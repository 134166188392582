<template>
    <section class="epigram-wrapper">
      <div class="top-box border-box">
        <p class="epigram-text" v-html="data.text"></p>
      </div>
      <div class="bottom-box">
        <div class="bottom-left-box border-box ocr-image-background" :style="{backgroundImage: `url(${data.ocrImage})`}"></div>
        <div class="bottom-right-box border-box ad-image-background" :style="{backgroundImage: `url(${data.adImage})`}"></div>
      </div>
      <EventLogo class="event-logo"/>
    </section>
</template>

<script>
import EventLogo from '@/components/EventLogo.vue'
export default {
  name: "Epigram",
  props: {
    data: Object
  },
  components: {
    EventLogo
  },
};
</script>
<style scoped>
.epigram-wrapper{
  max-width: 600px;
  max-height: 600px;
  min-width: 600px;
  min-height: 600px;
  padding: 30px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.border-box{
  border: 4px solid #000;
}
.top-box{
  width: 100%;
  height: 387px;
  overflow: hidden;
}
.bottom-box{
  margin-top: 10px;
  width: 607px;
  display: flex;
  justify-content: space-between;
}
.bottom-left-box{
  width: 250px;
  height: 120px;
}
.ad-image-background{
  background-position: center center;
  background-repeat: no-repeat; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.ocr-image-background{
  background-position: center center;
  background-repeat: no-repeat; 
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  background-color: #000;
}
.bottom-right-box{
  width: 330px;
  height: 120px;
}
.event-logo{
  margin-top: 30px;
}
.epigram-text {
  margin: 10px;
  word-wrap: break-word;
	font-family: 'Kanit_Black';
  font-size: 60px;
}
</style>
