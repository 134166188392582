<template>
  <section>
    <img :src="logo" style="width: 237px;"/>
  </section>
</template>

<script>
export default {
  name: "EventLogo",
  data(){
    return {
      logo: require('@/assets/images/tsdxadt.png')
    }
  },
};
</script>
