import { createRouter, createWebHistory } from 'vue-router'
import Editor from '@/Pages/Editor.vue'
import Tool from '@/Pages/Tool.vue'
const routes = [
    {
      path: '/',
      name: 'Editor',
      component: Editor
    },
    {
      path: '/tool',
      name: 'Tool',
      component: Tool
    }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
export default router