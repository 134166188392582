<template>
  <section>
    <svg v-if="data==null" xmlns="http://www.w3.org/2000/svg" width="100.778" height="115.214" viewBox="0 0 100.778 115.214">
      <g id="Group_3" data-name="Group 3" transform="translate(83.819 65.586)">
        <path id="Path_5" data-name="Path 5" d="M1183.229,317.8" transform="translate(-1175.259 -269.779)" fill="none" stroke="#1a1818" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
        <path id="Path_6" data-name="Path 6" d="M1175.023,268.339v36.472s-.38,8.471,7.973,11.549a11.708,11.708,0,0,0,7.891-9.65l.091-3.546V268.339Z" transform="translate(-1175.02 -268.339)" fill="none" stroke="#1a1818" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      </g>
      <path id="Path_7" data-name="Path 7" d="M1172.54,302.874V201.817h-82.822v64.016h0v37.041c0,10.827,9.5,12.157,9.5,12.157l81.3-.606C1172.16,311.349,1172.54,302.874,1172.54,302.874Z" transform="translate(-1088.718 -200.817)" fill="none" stroke="#1a1818" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      <line id="Line_1" data-name="Line 1" x2="47.616" transform="translate(18.223 31.14)" fill="none" stroke="#1a1818" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      <line id="Line_2" data-name="Line 2" x2="47.616" transform="translate(18.223 48.362)" fill="none" stroke="#1a1818" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      <line id="Line_3" data-name="Line 3" x2="28.177" transform="translate(18.223 65.585)" fill="none" stroke="#1a1818" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      <line id="Line_4" data-name="Line 4" x2="28.177" transform="translate(18.223 82.808)" fill="none" stroke="#1a1818" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    </svg>
    <img v-if="data" :src="data.base64" class="receipt"/>
  </section>
</template>

<script>
export default {
  name: "Receipt",
  props: {
    data: Object
  },
};
</script>
<style scoped>
.receipt{
  width: 228px;
}
</style>
