<template>
  <ul class="steps">
  <li class="step" :class="(state==='init')?'step--complete step--inactive':(state==='scanning')?'step--complete step--active':'step--complete step--active'">
    <span class="step__icon"></span>
    <span class="step__label">สแกน</span>
  </li>
  <li class="step" :class="(state==='init')?'step--incomplete step--inactive':(state==='scanning')?'step--complete step--inactive':'step--complete step--active'">
    <span class="step__icon"></span>
    <span class="step__label">ประมวลผล</span>
  </li>
  <li class="step" :class="(state==='init')?'step--incomplete step--inactive':(state==='scanning')?'step--incomplete step--inactive':'step--complete step--inactive'">
    <span class="step__icon"></span>
    <span class="step__label">คำคมที่ได้</span>
  </li>
</ul>
</template>
<script>
export default {
  props: {
    state: String
  },
};
</script>

<style scoped>
.steps {
  display: -webkit-box;
  display: flex;
  width: 100%;
  margin: 0;
  padding: 0 0 2rem 0;
  list-style: none;
}

.step {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex: 1;
  position: relative;
  pointer-events: none;
}
.step--active, .step--complete {
  cursor: pointer;
  pointer-events: all;
}
.step:not(:last-child):before, .step:not(:last-child):after {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 0.25rem;
  content: "";
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  will-change: width;
  z-index: 1;
}
.step:before {
  width: 100%;
  background-color: #e6e7e8;
}
.step:after {
  width: 0;
  background-color: #ff0000;
}
.step--complete:after {
  width: 0 !important;
  opacity: 1;
  -webkit-transition: width 0.6s ease-in-out, opacity 0.6s ease-in-out;
  transition: width 0.6s ease-in-out, opacity 0.6s ease-in-out;
}

.step__icon {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  width: 26px;
  height: 26px;
  background-color: #fff;
  border: 4px solid #e6e7e8;
  border-radius: 50%;
  color: transparent;
  font-size: 2rem;
}
.step__icon:before {
  display: block;
  content: "\25CF";
  font-size: 45px;
  margin-top: -7px;
  margin-left: 1px;
}
.step--complete.step--active .step__icon {
  color: #ff0000;
  -webkit-transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, color 0.3s ease-in-out;
}
.step--incomplete.step--active .step__icon {
  border-color: #e6e7e8;
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.step--complete.step--active .step__icon {
  z-index: 2;
}
.step--complete.step--active.step:before {
  width: 100%;
  background-color: #ff0000;
  z-index: 3;
}

.step--complete.step--inactive .step__icon {
  z-index: 2;
}

.step--incomplete.step--inactive .step__icon {
  z-index: 2;
}

.step--complete .step__icon {
  -webkit-animation: bounce 0.5s ease-in-out;
  animation: bounce 0.5s ease-in-out;
  border-color: #e6e7e8;
  color: #ff0000;
}

.step__label {
  position: absolute;
  bottom: -2rem;
  left: 50%;
  margin-top: 1rem;
  font-size: 0.8rem;
  text-transform: uppercase;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.step--incomplete.step--inactive .step__label {
  color: #e6e7e8;
}
.step--incomplete.step--active .step__label {
  color: #e6e7e8;
}
.step--active .step__label {
  -webkit-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}
@-webkit-keyframes bounce {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  33% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  66% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes bounce {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  33% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  66% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
</style>