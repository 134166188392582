<template>
    <section class="tools">
        <Epigram :data="epigram"/>
        <div class="test-text">
            <textarea v-model="epigram.text"></textarea>
        </div>
    </section>
</template>

<script>

import Epigram from '@/components/Epigram.vue'
export default {
    name: 'Tool',
    components: {
        Epigram,
    },
    data(){
        return {
            epigram: {
                text: '<strong>มาม่า</strong>เกาหลี<br>ที่ว่าเผ็ด<br>ยังไม่เด็ด<br>เท่าเราเลยนะ',
                adImage: require('@/assets/images/s8.jpg'),
                ocrImage: require('@/assets/images/error.jpg')
            }
        }
    }
}
</script>
<style scoped>
.tools{
    display: flex;
    align-items: center;
}
.test-text{
    margin: 30px;
    text-align: center;
    align-self: flex-start;
}
textarea {
  width:  calc(100vw - 730px);
  height: 395px;
  resize: none;
}
</style>