import { createApp } from 'vue'
import App from './App'
import router from './router'
import VueGtag from "vue-gtag"
import { VueReCaptcha } from "vue-recaptcha-v3"

const app = createApp(App);

app.use(VueGtag, {
  config: {
    id: "UA-206587875-2"
  }
});

app.use(router);
app.use(VueReCaptcha, { siteKey: "6Lf_mFEcAAAAAG8x1Bhezvxk4uhlgZaVV86qbq2z" })
app.mount("#app");
