<template>
  <section>
    <svg xmlns="http://www.w3.org/2000/svg" width="18.847" height="18.859" viewBox="0 0 18.847 18.859">
  <path id="Icon_material-refresh" data-name="Icon material-refresh" d="M8.785,8.77a9.431,9.431,0,1,1-2.452,9.017H8.785a7.069,7.069,0,1,0,6.659-9.429,6.972,6.972,0,0,0-4.974,2.1l3.8,3.8H6.015V6Z" transform="translate(-6.015 -6)" fill="#fff"/>
</svg>
  </section>
</template>

<script>
export default {
  name: "Reload"
};
</script>