<template>
<section>
    <section class="epigram-export">
      <Epigram id="epigram" :data="epigram"/>
    </section>
    <section class="wrapper">
        <div class="header">
            <div class="header-title">
                <div>คำคม</div>
                <div>สะดวกใช้</div>
            </div>
            <div class="sub-header-title">
                <div>จากใบเสร็จร้านสะดวกซื้อ</div>
            </div>
        </div>
        <div class="content">
          <StepProgress :state="state" class="progress-bar"/>
          <input
            style="display: none"
            type="file"
            ref="uploadInput"
            accept="image/png, image/jpeg"
            :multiple="false"
            @change="detectFiles($event)"
          />
          <div class="epigram-preview-box" v-if="state==='complete'">
            <img :src="epigram.preview" class="epigram-preview"/>
          </div>
          <div class="scanner-box" v-if="state!=='complete'">
            <div class="scanner" @click="uploadImage()">
              <div class="scanner-line" v-if="state=='scanning'"></div>
              <Receipt class="receipt-holder" :data="image"/>
            </div>
          </div>
          <div class="bubble" v-if="state=='init'" @click="uploadImage()">
            <div class="hint">
              <div style="font-size:16px;">ถ่าย หรือ อัปโหลด</div>
              <div style="font-size:20px;">ภาพใบเสร็จ</div>
            </div>
          </div>
          <div class="indicator" v-if="state=='scanning'">
            <div class="status">
              <div style="font-size:16px;">กำลังสแกน...</div>
            </div>
          </div>
          <div class="retry" v-if="state=='complete'">
            <div class="retry-button" @click="reset()">
              <Reload class="retry-icon"/>
              <div class="retry-text">
                เล่นใหม่
              </div>
            </div>
          </div>
        </div>
        <div class="event-logo">
            <EventLogo/>
        </div>
        <br class="clear" />
    </section>
    <div class="footer">
        <div class="footer-container">
            <div class="left-footer">OCR Technology</div>
            <div class="right-footer footer-text-bold">STRATEGY FORUM</div>
        </div>
        <div class="recaptcha">
            <div>This site is protected by reCAPTCHA and the Google</div>
            <div>
                <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
            </div>
        </div>
    </div>
</section>
</template>

<script>
import Epigram from '@/components/Epigram.vue'
import EventLogo from '@/components/EventLogo.vue'
import Receipt from '@/components/Receipt.vue'
import Reload from '@/components/Reload.vue'
import StepProgress from '@/components/StepProgessSlider'
import * as htmlToImage from 'html-to-image'
import {bytesToSize, getBase64Image} from '@/helpers'
import axios from 'axios'
import loadImage from 'blueimp-load-image'
import { useReCaptcha } from "vue-recaptcha-v3"
export default {
    name: 'Editor',
    components: {
        Epigram,
        EventLogo,
        Receipt,
        Reload,
        StepProgress
    },
    data(){
        return {
            count: 1,
            state: 'init',
            image: null,
            epigram: {
                text: '<strong>มาม่า</strong>เกาหลี<br>ที่ว่าเผ็ด<br>ยังไม่เด็ด<br>เท่าเราเลยนะ',
                adImage: require('@/assets/images/s8.jpg'),
                ocrImage: require('@/assets/images/error.jpg'),
                preview: null
            },
            loading: false,
            executeRecaptcha: null,
            recaptchaLoaded: null,
            token: null,
        }
    },
    async created() {
		await this.loadRecaptcha()
	},
    mounted(){
        this.eventLogger('epigram', 'loaded', 1)
    },
    methods:{
        async loadRecaptcha() {
			const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();
            this.executeRecaptcha = executeRecaptcha
            this.recaptchaLoaded = recaptchaLoaded
			await this.recaptchaLoaded();
			this.token = await this.executeRecaptcha('epigram');
		},
        async reset(){
            this.count += 1
            this.state = 'init'
            this.image = null
            this.epigram.preview = null
            this.setDefaultText()
            this.token = await this.executeRecaptcha('epigram');
            this.eventLogger('epigram', 'play_again', this.count)
        },
        uploadImage(){
            if(!this.loading) {
                this.eventLogger('epigram', 'selected_image', 1)
                this.$refs.uploadInput.click()
            }
        },
        detectFiles (e) {
            var self = this
            this.loading = true
            const file = e.target.files[0]
            loadImage( file, (img) => { 
                self.image = {
                    name: file.name,
                    size: bytesToSize(file.size),
                    originsize: file.size,
                    base64: getBase64Image(img)
                }
                self.analyzingImage()
            }, { orientation: true } );
            e.target.value = "";
        },
        analyzingImage() {
            var self = this
            this.state = 'scanning'
            self.eventLogger('epigram', 'uploaded_image', self.image.originsize)
            axios.post('https://ocr-duooxhmela-de.a.run.app/bill', {
                base64: self.image.base64,
                token: self.token
            })
            .then(function (response) {
                self.eventLogger('epigram', 'ocr_response_success', response.data.Key)
                if(response.status == 200 && response.data.Result == 'success') {
                    self.epigram.text = response.data.Phrase
                    self.epigram.ocrImage = response.data.Textlocation
                    self.epigram.adImage = response.data.Photo
                    self.convertToImage()
                }
                else{
                    self.setDefaultText()
                    self.convertToImage()
                    self.eventLogger('epigram', 'ocr_response_error', response.data.Result)
                }
            })
            .catch(function (error) {
                self.setDefaultText()
                self.convertToImage()
                self.eventLogger('epigram', 'api_error', error.message)
            });
        },
        setDefaultText(){
            this.epigram.text = '<strong>ลองใหม่</strong>อีกครั้ง'
            this.epigram.adImage = require('@/assets/images/s8.jpg')
            this.epigram.ocrImage = require('@/assets/images/error.jpg')
        },
        convertToImage(){
            var self = this
            htmlToImage.toJpeg(document.getElementById('epigram'))
            .then( (dataUrl) => {
                self.epigram.preview = dataUrl
                self.state = 'complete'
                self.loading = false
            });
        },
        eventLogger(Category, Action, Label){
            this.$gtag.query('event', Action, {
                event_category: Category,
                event_label: Label
            })
        }
    }
}
</script>
<style scoped>
.epigram-export{
    position: absolute;
    margin-top: -660px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.wrapper { 
    width: 100%;
    margin: 0 auto;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0 0 30px 30px;
}

.header{
    margin-top: 30px;
    max-width: 414px;
    display: flex;
    flex-direction: column;
    align-items:flex-start;
}
.header-title{
    margin: 15px;
    font-family: 'Kanit_Black';
    font-size: 82px;
    line-height: 66px;
}

.sub-header-title{
    margin-left: 15px;
    font-size: 24px;
}
.content{
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
    min-height: 450px;
}
.progress-bar{
	width: 414px;	
}
.scanner-box{
	margin-top: 30px;
	width: 250px;
	height: 250px;
	background: 
		url('../assets/images/top-left-corner.png') left 0px top 0px no-repeat, 
		url('../assets/images/top-right-corner.png') right 0px top 0px no-repeat, 
		url('../assets/images/bottom-left-corner.png') left 0px bottom 0px no-repeat, 
		url('../assets/images/bottom-right-corner.png') right 0px bottom 0px no-repeat, 
		white;
	padding: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.scanner{
	width: 228px;
	height: 228px;
	position: relative;
	overflow: hidden;
	background-color: #EFEFEF;
	border-radius: 24px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
    z-index:3;
}
.receipt-holder{
	position: absolute;
	z-index:1;
}

.scanner-line{
	position: absolute;
	z-index:2;
	height: 300px;
	width: 228px;
	background: linear-gradient(180deg, rgba(255, 0, 0, 0) 43%, rgb(255, 0, 0, 1) 211%);
	border-bottom: 3px solid rgb(255, 0, 0, 1);
	transform: translateY(-100%);
	animation: radar-beam 2s infinite;
	animation-timing-function: cubic-bezier(0.53, 0, 0.43, 0.99);
}
@keyframes radar-beam {
	100% { transform: translate(0, 100%);}
}
.bubble {
	top:-16px;
	position:relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.hint{
	background: #ff0000;
	color: #FFFFFF;
	width: 200px;
	height: 60px;
	border-radius: 15px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.hint:after {
	content: '';
	position: absolute;
	display: block;
	width: 0;
	z-index: 1;
	border-style: solid;
	border-color: #ff0000 transparent;
	border-width: 0 20px 20px;
	top: -20px;
	left: 50%;
	margin-left: -20px;
}
.indicator{
	z-index:3;
	top:-55px;
	position:relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.status{
	width: 100px;
	height: 30px;
	border-radius: 15px;
	background: #000;
	color: #FFFFFF;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.retry{
	z-index:3;
	top:-28px;
	position:relative;
}
.retry-button{
	width: 148px;
	height: 54px;
	border-radius: 15px;
	background: #000;
	color: #FFFFFF;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
.retry-icon{
	margin-top: 5px;
}
.retry-text{
	font-size: 16px;
	margin-top: -5px;
	margin-left: 10px;
}
.epigram-preview-box{
	margin-top: 30px;
	width: 289px;
	height: 277px;
	box-shadow: 0px 10px 15px 0px rgb(50 50 50 / 40%);
	border-radius: 15px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.epigram-preview{
	width: 277px;
	height: 265px;
}
.event-logo {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
    margin-bottom: 10px;
}

.footer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-container{
    font-size: 12px;
    color: #fff;
    width: 414px;
    height: 34px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-text-bold{
    font-weight: bold;
}

.left-footer{
    margin-left: 50px;
}
.right-footer{
    margin-right: 50px;
}


@media screen and (max-height: 667px) {
    .event-logo { 
        position: initial;
    }
}

@media screen and (max-width: 767px) {
    .wrapper { 
        width: 415px;
    }
	.footer{
		width: 415px;
	}
}

.clear { 
    clear: both; 
}

.recaptcha{
    color: #e4e4e4;
    font-size: 10px;
    display: flex;
	flex-direction: column;
    align-items: center;
} 

a {
    color: #e4e4e4;
    text-decoration: none;
}

</style>
